import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import Photo from "../../images/wallpapers/Accessible_2.jpg";
import PhotoTwo from "../../images/wallpapers/Accessible_Tourism.jpeg";
import { Breadcrumbs } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function Tharaka() {
  const { t } = useTranslation("tharaka");
  return (
    <div>
      <Navbar />
      <div>
        <div className="2xl:px-40 xl:px-24 lg:px-20 px-5 mt-20 gap-x-5">
          {/* ===========Breadcrumbs=========== */}
          <Breadcrumbs>
            <a href="/" className="opacity-60">
              <i class="fa-solid fa-house"></i>
            </a>
            <a href="/Accessible" className="opacity-60 font-bold uppercase">
              <span> {t("breadcrumbs01")}</span>
            </a>
            <a href="/Tharaka" className="opacity-60 font-bold">
              <span> {t("breadcrumbs02")}</span>
            </a>
          </Breadcrumbs>
          {/* ===========Breadcrumbs=========== */}
          <div className="lg:mt-10 mt-5 xl:flex gap-x-5">
            <div>
              <img
                src={Photo}
                alt=""
                className="w-full lg:h-96 md:h-[300px] object-cover"
              />
            </div>
            <div className="xl:w-5/6 text-justify md:space-y-8 space-y-4 mt-4 xl:mt-0 text-sm lg:text-base">
              <p>{t("para01")}</p>
              <p>{t("para02")}</p>
              <p>{t("para03")}</p>
            </div>
          </div>
        </div>
        <div className="2xl:px-40 xl:px-24 lg:px-20 px-5 mt-4 space-y-4 text-justify text-sm lg:text-base">
          <p>{t("para04")}</p>
          <p>{t("para05")}</p>
          <p>{t("para06")}</p>
          <p>{t("para07")}</p>
          <p>{t("para08")}</p>
        </div>
        <div className="grid grid-cols-2 2xl:px-40 xl:px-24 lg:px-20 px-5 gap-x-5 mt-5">
          <img
            src={Photo}
            alt=""
            className="w-full lg:h-[350px] object-cover"
          />
          <img
            src={PhotoTwo}
            alt=""
            className="w-full lg:h-[350px] object-cover"
          />
        </div>
        <div className="2xl:px-40 xl:px-24 lg:px-20 px-5 space-y-4 my-5 text-justify text-sm lg:text-base">
          <p>{t("para09")}</p>
          <p>{t("para10")}</p>
          <p>{t("para11")}</p>
          <p>{t("para12")}</p>
          <p>{t("para13")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
