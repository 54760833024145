import React from "react";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Car from "../images/wallpapers/premio.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhatsApp from "../components/WhatsApp";
import ScrollToTopButton from "../components/ScrollToTopButton";
import { useTranslation } from "react-i18next";

export default function Vehicles() {
  const { t } = useTranslation("vehicles");
  const settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  return (
    <div>
      <Navbar />
      <div>
        <div className="xl:h-[500px] lg:h-[400px] h-[300px] xl:mt-[70px] mt-[60px]">
          <img
            src={Car}
            className="object-cover w-full h-full"
            alt="Wallpaper"
          />
        </div>
        <div className="mt-10 2xl:px-40 xl:px-24 lg:px-20 px-5">
          <p className="text-center lg:text-3xl md:text-2xl text-lg font-bold text-[#54B435]">
            {t("topic")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para01")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para02")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para03")}
          </p>
          <p className="lg:text-3xl md:text-2xl text-lg font-bold mt-10 text-[#54B435]">
            {t("subtopic01")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para04")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para05")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para06")}
          </p>
          <p className="lg:text-3xl md:text-2xl text-lg font-bold mt-10 text-[#54B435]">
            {t("subtopic02")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para07")}
          </p>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("listtopic")}
          </p>
          <ul className="list-disc lg:text-lg md:text-base text-sm mt-5 md:ml-10 ml-8">
            <li>{t("list01")}</li>
            <li>{t("list02")}</li>
            <li>{t("list03")}</li>
          </ul>
          <p className="lg:text-lg md:text-base text-sm pt-5 text-justify">
            {t("para08")}
          </p>
        </div>
        <div className="mb-10">
          <div className="2xl:px-40 xl:px-24 lg:px-20 md:px-10 px-5">
            <div className="lg:grid grid-cols-2 mt-10 bg-slate-200 items-center shadow-md">
              <div>
                <Slider {...settings}>
                  <img
                    src="./vehicles/rav4/rav4-5.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/rav4/rav4-2.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/rav4/rav4-3.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/rav4/rav4-4.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                </Slider>
              </div>
              <div className="p-5">
                <p className="font-bold md:text-xl text-lg">
                  {t("vehivleName01")}
                </p>
                <p className="mt-4 text-justify md:text-base text-sm">
                  {t("vehicleDetail")}
                </p>
              </div>
            </div>
            <div className="lg:grid grid-cols-2 mt-10 bg-slate-200 items-center shadow-md">
              <div className="order-2">
                <Slider {...settings}>
                  <img
                    src="./vehicles/hyundai/Hyundai-1.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/hyundai/Hyundai-7.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/hyundai/Hyundai-2.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/hyundai/Hyundai-4.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/hyundai/Hyundai-6.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                  <img
                    src="./vehicles/hyundai/Hyundai-5.jpg"
                    className="xl:h-[300px] w-full lg:h-[400px] h-[300px] object-cover"
                    alt=""
                  />
                </Slider>
              </div>
              <div className="p-5 order-1">
                <p className="font-bold md:text-xl text-lg">
                  {t("vehivleName02")}
                </p>
                <p className="mt-4 text-justify md:text-base text-sm"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <WhatsApp />
      <ScrollToTopButton />
    </div>
  );
}
