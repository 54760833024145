import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Access from "../images/wallpapers/Accessible_Tourism.jpeg";
import AccessTwo from "../images/wallpapers/Accessible_2.jpg";
import { Breadcrumbs } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function Accessible() {
  const { t } = useTranslation("accessible");
  const special = [
    {
      img: `../team/person (2).png`,
      name: t("special.name"),
      topic: t("special.topic"),
      detail: t("special.detail"),
      seeMore: `/Tharaka`,
    },
  ];
  return (
    <div>
      <Navbar />
      <div>
        <div className="xl:h-[500px] lg:h-[400px] h-[300px] xl:mt-[70px] mt-[60px]">
          <img
            src={Access}
            className="object-cover w-full h-full brightness-[60%]"
            alt="Wallpaper"
          />
          <p className="lg:text-5xl md:text-4xl text-3xl text-center absolute xl:mt-[285px] lg:mt-[220px] mt-[180px] top-0 right-0 left-0 text-white font-semibold">
            {t("topic")}
          </p>
        </div>
        {/* ===========Breadcrumbs=========== */}
        <Breadcrumbs className="justify-center">
          <a href="/" className="opacity-60">
            <i class="fa-solid fa-house"></i>
          </a>
          <a href="/Accessible" className="opacity-60 font-bold uppercase">
            <span> {t("breadcrumbs")}</span>
          </a>
        </Breadcrumbs>
        {/* ===========Breadcrumbs=========== */}
      </div>
      <div className="mt-10 2xl:px-40 xl:px-24 lg:px-20 px-5 text-justify gap-x-5 grid lg:grid-cols-2 grid-cols-1 items-center">
        <div className="xl:text-base text-sm">
          <p>{t("para01")}</p>
          <p className="mt-5">{t("para02")}</p>
        </div>
        <div className="flex justify-center items-center mt-10 lg:mt-0">
          <img
            src={AccessTwo}
            alt=""
            className="w-full md:h-[300px] object-cover"
          />
        </div>
      </div>
      <div className="my-10 2xl:px-40 xl:px-24 lg:px-20 px-5">
        <div>
          <p className="flex justify-center md:text-3xl text-xl font-bold">
            {t("subtopic")}
          </p>
          <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-5 mt-10 gap-y-5">
            {special.map((spec, index) => (
              <div class="items-center bg-gray-100 hover:bg-yellow-100 transition-colors duration-1000 rounded-lg shadow-md sm:flex cursor-pointer">
                <a
                  href={spec.seeMore ? spec.seeMore.toLowerCase() : "#"}
                  className="flex justify-center bg-green-200 md:h-full items-center md:rounded-l-lg"
                >
                  <img
                    class="max-w-40 h-40 object-cover rounded-lg sm:rounded-none sm:rounded-l-lg md:my-0 my-3"
                    src={spec.img}
                    alt=""
                  />
                </a>
                <div class="p-5">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900">
                    <a href={spec.seeMore ? spec.seeMore.toLowerCase() : "#"}>
                      {spec.name}
                    </a>
                  </h3>
                  <span class="text-gray-500 ">{spec.topic}</span>
                  <p class="mt-3 mb-4 font-medium text-slate-800 text-justify text-sm">
                    <a href={spec.seeMore ? spec.seeMore.toLowerCase() : "#"}>
                      {spec.detail}
                    </a>
                  </p>
                  <div className="flex justify-end">
                    <a
                      href={spec.seeMore}
                      className="font-bold bg-black text-white px-5 py-2 rounded-lg"
                    >
                      {t("seemore")}
                    </a>
                  </div>
                  <ul class="flex space-x-4 sm:mt-0"></ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
