import Navbar from "../components/Layout/Navbar";
import WhatsApp from "../components/WhatsApp";
import ScrollToTopButton from "../components/ScrollToTopButton";
import { useTranslation } from "react-i18next";

export default function Trips() {
  const { t } = useTranslation("trips");
  const tripList = [
    {
      img: `/trips/nuwara_eliya_post_office.jpg`,
      topic: t("tripList01.topic"),
      day: t("tripList01.day"),
      town: t("tripList01.town"),
      detail: t("tripList01.detail"),
      price: `1180`,
      seeMore: `/SevenDays`,
    },
    {
      img: `/trips/coconut_tree_hill.jpg`,
      topic: t("tripList02.topic"),
      day: t("tripList02.day"),
      town: t("tripList02.town"),
      detail: t("tripList02.detail"),
      price: `1600`,
      seeMore: `/TenDays`,
    },
    {
      img: `/trips/Sigiriya_Rock.jpg`,
      topic: t("tripList03.topic"),
      day: t("tripList03.day"),
      town: t("tripList03.town"),
      detail: t("tripList03.detail"),
      price: `2100`,
      seeMore: `/FourteenDays`,
    },
    {
      img: `/trips/Dutch_Fort.jpg`,
      topic: t("tripList04.topic"),
      day: t("tripList04.day"),
      town: t("tripList04.town"),
      detail: t("tripList04.detail"),
      price: `4200`,
      seeMore: `/ThirtyDays`,
    },
    {
      img: `/trips/kandy_view.jpg`,
      topic: t("tripList05.topic"),
      day: t("tripList05.day"),
      town: t("tripList05.town"),
      detail: t("tripList05.detail"),
      price: `6500`,
      seeMore: `/FortyFiveDays`,
    },
    {
      img: `/trips/Ruwanweli-Maha-Seya.jpg`,
      topic: t("tripList06.topic"),
      day: t("tripList06.day"),
      town: t("tripList06.town"),
      detail: t("tripList06.detail"),
      price: `8700`,
      seeMore: `/SixtyDays`,
    },
  ];
  return (
    <div>
      <Navbar />
      <div className="2xl:px-40 xl:px-24 lg:px-20 px-5 lg:my-20 my-10">
        <div className="justify-center mb-10">
          <p className="font-bold text-center xl:text-4xl lg:text-3xl md:text-2xl text-xl">
            {t("checkout")}
          </p>
          <p className="mt-5 md:text-center text-justify xl:text-lg lg:text-base text-sm">
            {t("subdetail")}
          </p>
        </div>
        <div className="grid items-center lg:gap-10 gap-5 xl:grid-cols-3 md:grid-cols-2">
          {tripList.map((trip, index) => (
            <div
              key={index}
              class=" bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transform hover:scale-105 duration-500 ease-in-out h-full justify-between flex flex-col"
            >
              <a href={trip.seeMore ? trip.seeMore.toLowerCase() : "#"}>
                <img
                  class="rounded-t-lg lg:h-[250px] h-[150px] w-full object-cover transform hover:scale-105 duration-500 ease-in-out"
                  src={trip.img}
                  alt=""
                />
              </a>
              <div class="p-5">
                <a href={trip.seeMore ? trip.seeMore.toLowerCase() : "#"}>
                  <h5 class="font-bold tracking-tight text-gray-900 text-xl lg:text-2xl ">
                    {trip.topic}
                  </h5>
                </a>
                <p class="mb-3 font-medium text-gray-700 lg:text-base text-sm">
                  {trip.day}
                </p>
                <p class="mb-3 font-normal text-gray-700 italic lg:text-base text-sm">
                  {trip.town}
                </p>
                <p className="mb-3 font-medium lg:text-base text-sm">
                  {trip.detail}
                </p>
              </div>
              <div className="p-5">
                <div className="flex justify-between items-center">
                  <a
                    href={trip.seeMore ? trip.seeMore.toLowerCase() : "#"}
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#54B435] hover:text-white border-2 border-[#54B435] transition-colors duration-300 bg-white  rounded-lg hover:bg-[#54B435]"
                  >
                    {t("readmore")}
                    <i class="fa-solid fa-arrow-right pl-3"></i>
                  </a>
                  <div className="leading-3">
                    {/* <span className="text-red-600 font-bold text-sm flex justify-end">
                      Starting From
                    </span>
                    <span className="text-red-600 font-bold text-xl flex justify-end">
                      USD {trip.price}
                    </span>
                    <span className="text-sm font-medium flex justify-end">
                      per person
                    </span> */}
                    <button className="bg-gray-900 hover:bg-white transition-colors duration-300 font-semibold text-white hover:text-gray-900 lg:px-5 px-3 py-1 leading-5 rounded-md border-2 border-gray-900 lg:text-base text-sm">
                      {t("getyour1")} <br /> {t("getyour2")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <WhatsApp />
      <ScrollToTopButton />
    </div>
  );
}
