import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import menuEN from "./locales/en/menu.json";
import menuDE from "./locales/de/menu.json";
import tendaysEN from "./locales/en/tendays.json";
import tendaysDE from "./locales/de/tendays.json";
import fourteendaysEN from "./locales/en/fourteendays.json";
import fourteendaysDE from "./locales/de/fourteendays.json";
import thirtydaysEN from "./locales/en/thirtydays.json";
import thirtydaysDE from "./locales/de/thirtydays.json";
import fortyfivedaysEN from "./locales/en/fortyfivedays.json";
import fortyfivedaysDE from "./locales/de/fortyfivedays.json";
import sixtydaysEN from "./locales/en/sixtydays.json";
import sixtydaysDE from "./locales/de/sixtydays.json";
import homeEN from "./locales/en/home.json";
import homeDE from "./locales/de/home.json";
import tripsEN from "./locales/en/trips.json";
import tripsDE from "./locales/de/trips.json";
import toursEN from "./locales/en/tours.json";
import toursDE from "./locales/de/tours.json";
import contactformEN from "./locales/en/contactform.json";
import contactformDE from "./locales/de/contactform.json";
import vehiclesEN from "./locales/en/vehicles.json";
import vehiclesDE from "./locales/de/vehicles.json";
import accessibleEN from "./locales/en/accessible.json";
import accessibleDE from "./locales/de/accessible.json";
import tharakaEN from "./locales/en/tharaka.json";
import tharakaDE from "./locales/de/tharaka.json";
import contactusEN from "./locales/en/contactus.json";
import contactusDE from "./locales/de/contactus.json";

const resources = {
  en: {
    menu: menuEN,
    translation: translationEN,
    tendays: tendaysEN,
    fourteendays: fourteendaysEN,
    thirtydays: thirtydaysEN,
    fortyfivedays: fortyfivedaysEN,
    sixtydays: sixtydaysEN,
    home: homeEN,
    trips: tripsEN,
    tours: toursEN,
    contactform: contactformEN,
    vehicles: vehiclesEN,
    accessible: accessibleEN,
    tharaka: tharakaEN,
    contactus: contactusEN,
  },
  de: {
    menu: menuDE,
    translation: translationDE,
    tendays: tendaysDE,
    fourteendays: fourteendaysDE,
    thirtydays: thirtydaysDE,
    fortyfivedays: fortyfivedaysDE,
    sixtydays: sixtydaysDE,
    home: homeDE,
    trips: tripsDE,
    tours: toursDE,
    contactform: contactformDE,
    vehicles: vehiclesDE,
    accessible: accessibleDE,
    tharaka: tharakaDE,
    contactus: contactusDE,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
