import { useTranslation } from "react-i18next";

export default function Tours() {
  const { t } = useTranslation("tours");
  const tourType = [
    {
      topic: t("tourType01.topic"),
      details: t("tourType01.details"),
      btn: `/Trips`,
      img: `tours/tourism.jpg`,
      alt: `Tourism`,
    },
    {
      topic: t("tourType02.topic"),
      details: t("tourType02.details"),
      btn: `/`,
      img: `tours/summer.jpg`,
      alt: `Summer Camps`,
    },
    {
      topic: t("tourType03.topic"),
      details: t("tourType03.details"),
      btn: `/Accessible`,
      img: `tours/accessible.jpg`,
      alt: `Accessible_Tourism`,
    },
  ];
  return (
    <div>
      <div className="absolute top-0 right-0 left-0 px-5 lg:mt-10  mt-10 2xl:px-40 xl:px-24 lg:px-20">
        <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-x-10 gap-y-10">
          {tourType.map((tour) => (
            <div className="flex justify-center">
              <div className="bg-gradient-to-bl from-lime-300 to-amber-100 shadow-lg rounded-lg lg:w-full w-[500px]">
                <img
                  src={tour.img}
                  alt={tour.alt}
                  className="w-full h-48 object-cover rounded-t-lg"
                />
                <div className="p-5">
                  <p className="text-xl font-bold md:text-2xl xl:text-3xl text-center uppercase">
                    {tour.topic}
                  </p>
                  <p className="mt-2 text-sm font-medium text-center xl:text-base">
                    {tour.details}
                  </p>
                  <a
                    href={tour.btn}
                    className="w-full inline-flex justify-center"
                  >
                    <input
                      type="button"
                      value={t("seemore")}
                      className="bg-black hover:bg-[#54B435] transition-colors duration-300 mt-5 xl:py-2 xl:px-10 lg:py-1.5 lg:px-8 py-1 px-8 lg:text-xl text-base rounded-lg font-bold text-white hover:text-white cursor-pointer"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
