/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import "./home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhatsApp from "../components/WhatsApp";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Trips from "./Trips";
import Tours from "./Tours";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation("home");

  const galleryImages = [
    process.env.PUBLIC_URL + `./trips/coconut_tree_hill.jpg`,
    process.env.PUBLIC_URL + `./trips/Galle_Fort.jpg`,
    process.env.PUBLIC_URL + `./trips/nineArch.jpg`,
    process.env.PUBLIC_URL + `./trips/pinnawala.jpg`,
    process.env.PUBLIC_URL + `./trips/damro.jpg`,
    process.env.PUBLIC_URL + `./trips/templeTooth.jpg`,
    process.env.PUBLIC_URL + `./trips/Dambulla_Cave_Temple.jpg`,
    process.env.PUBLIC_URL + `./trips/Kandy_Cultural_Dance.jpg`,
    process.env.PUBLIC_URL + `./trips/nuwara_eliya_post_office.jpg`,
    process.env.PUBLIC_URL + `./trips/yala.jpg`,
    process.env.PUBLIC_URL + `./trips/Madu-River.jpg`,
    process.env.PUBLIC_URL + `./trips/whale-watching.jpg`,
    process.env.PUBLIC_URL + `./trips/mirissa-bay.jpg`,
    process.env.PUBLIC_URL + `./trips/Gregory_Lake.jpg`,
    process.env.PUBLIC_URL + `./trips/Little_Adam.jpg`,
    process.env.PUBLIC_URL + `./trips/Hiriketiya-Beach.jpg`,
    process.env.PUBLIC_URL + `./trips/sigiriya.jpg`,
    process.env.PUBLIC_URL + `./trips/Koneswaram_temple.jpg`,
    process.env.PUBLIC_URL + `./trips/Jaffna_Library.jpg`,
    process.env.PUBLIC_URL + `./trips/Dutch_Fort.jpg`,
    process.env.PUBLIC_URL + `./trips/Gangaramaya_Temple.jpg`,
    process.env.PUBLIC_URL + `./trips/Arugam_Bay.jpg`,
    process.env.PUBLIC_URL + `./trips/Pigeon_Island.jpg`,
  ];

  const choose = [
    {
      img: `/choose/tailor.png`,
      details: t("choose01.c_details"),
    },
    {
      img: `/choose/staff.png`,
      details: t("choose02.c_details"),
    },
    {
      img: `/choose/match.png`,
      details: t("choose03.c_details"),
    },
    {
      img: `/choose/24h.png`,
      details: t("choose04.c_details"),
    },
  ];

  const media = [
    {
      img: `/media/ceoworld.png`,
      topic: t("media01.m_topic"),
      detail: t("media01.m_details"),
      seeMedia: `https://ceoworld.biz/2024/04/15/worlds-best-countries-to-visit-in-your-lifetime-2024/`,
    },
    {
      img: `/media/national_geographic.png`,
      topic: t("media02.m_topic"),
      detail: t("media02.m_details"),
      seeMedia: `https://www.nationalgeographic.com/culture/article/best-of-the-world-20-experiences-for-2024`,
    },
    {
      img: `/media/the_times.png`,
      topic: t("media03.m_topic"),
      detail: t("media03.m_details"),
      seeMedia: `https://www.thetimes.co.uk/article/25-holidays-to-the-tiny-tropical-island-tipped-to-take-off-in-2024-vk29x7pfp`,
    },
    {
      img: `/media/cntraveler.png`,
      topic: t("media04.m_topic"),
      detail: t("media04.m_details"),
      seeMedia: `https://www.cntraveler.com/story/gold-list-top-hotels-and-resorts-in-the-world`,
    },
    {
      img: `/media/travel_leisure.png`,
      topic: t("media05.m_topic"),
      detail: t("media05.m_details"),
      seeMedia: `https://www.travelandleisure.com/best-places-to-go-2024-8385979`,
    },
    {
      img: `/media/forbes.png`,
      topic: t("media06.m_topic"),
      detail: t("media06.m_details"),
      seeMedia: `https://www.forbes.com/sites/laurabegleybloom/2023/03/30/the-23-best-places-to-travel-in-2023-chosen-by-the-experts/?sh=4940c33176da`,
    },
    {
      img: `/media/harpersbazaar.png`,
      topic: t("media07.m_topic"),
      detail: t("media07.m_details"),
      seeMedia: `https://www.harpersbazaar.com/uk/travel/a40485365/sri-lanka-luxury-guide/`,
    },
    {
      img: `/media/the_telegraph.png`,
      topic: t("media08.m_topic"),
      detail: t("media08.m_details"),
      seeMedia: `https://www.telegraph.co.uk/travel/destinations/20-places-must-visit-2023/`,
    },
    {
      img: `/media/independent.png`,
      topic: t("media09.m_topic"),
      detail: t("media09.m_details"),
      seeMedia: `https://www.independent.co.uk/travel/inspiration/best-destinations-travel-2023-b2249166.html`,
    },
  ];

  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplaySpeed: 4000,
    autoplay: true,
    arrows: false,
  };

  const MediaSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const mobileSettings = {
    ...MediaSettings,
    slidesToShow: 1,
    arrows: false,
  };

  const tabletSettings = {
    ...MediaSettings,
    slidesToShow: 2,
    arrows: false,
  };

  const tabletLargeSettings = {
    ...MediaSettings,
    slidesToShow: 2,
  };

  const desktopSettings = {
    ...MediaSettings,
    slidesToShow: 3,
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar />
      <div>
        <Slider {...settings}>
          {galleryImages.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Gallery Image ${index + 1}`}
                className="brightness-[60%] w-full h-[540px] object-cover xl:mt-[70px] lg:mt-[56px] mt-[60px]"
              />
            </div>
          ))}
        </Slider>
        <div className="absolute top-0 w-full px-5 md:mt-40 mt-28 2xl:px-40 xl:px-24 lg:px-20">
          <div>
            <p className="font-serif text-4xl font-bold tracking-widest text-center text-yellow-300 xl:text-6xl lg:text-5xl md:text-5xl uppercase">
              {t("ayubowan")}
            </p>
            <p className="mt-2 text-lg font-bold text-center text-white xl:text-2xl lg:text-xl uppercase">
              {t("subOne")}{" "}
              <span className="text-[#79c234]"> {t("subTwo")} </span>
            </p>
            <p className="mt-24 text-2xl font-bold text-center text-gray-100 lg:text-4xl">
              {t("welcome")}
            </p>
            <p className="p-5 mt-10 font-serif text-base font-bold text-center border-2 md:mt-20 xl:text-2xl lg:text-xl bg-gray-50 opacity-70 lg:leading-10">
              {t("subThree")}
            </p>
          </div>
        </div>
      </div>

      <div className="px-5 2xl:px-40 xl:px-24 lg:px-20">
        <div className="grid pt-10 lg:grid-cols-2 lg:gap-x-10 gap-y-10 items-center">
          <p className="font-bold lg:text-[38px] md:text-3xl text-2xl text-center flex items-center font-serif xl:leading-[70px] uppercase">
            {t("craft")}
          </p>
          <p className="text-sm font-medium text-justify xl:text-lg lg:text-base">
            {t("discover1")}{" "}
            <span className="font-bold">
              travel<span className="text-[#54B435]">mango</span>
            </span>{" "}
            {t("discover2")}
          </p>
        </div>
      </div>

      <div className="relative mt-10">
        <img
          src="./wallpapers/wallpaper10.jpg"
          alt=""
          className="opacity-40 w-full lg:h-[530px] md:h-[1300px] h-[1350px] object-cover lg:mt-20 mt-20 md:mt-0"
        />
        <Tours />
      </div>

      <div className="2xl:px-40 xl:px-24 lg:px-20 px-5 bg-slate-100 py-10">
        <p className="font-bold text-center xl:text-4xl lg:text-3xl md:text-2xl text-xl uppercase">
          {t("best")}
        </p>
        <p className="mt-5 md:text-center text-justify lg:text-lg md:text-base text-sm">
          {t("subfour")}
        </p>

        <div className="grid grid-cols-1 mt-10">
          <div className="mx-auto w-full max-w-7xl">
            <Slider
              {...(windowWidth <= 640
                ? mobileSettings
                : windowWidth <= 768
                ? tabletSettings
                : windowWidth <= 1024
                ? tabletLargeSettings
                : desktopSettings)}
            >
              {media.map((m) => (
                <div className="px-0 md:px-5">
                  <div class="bg-white border border-gray-200 rounded-lg shadow lg:h-[650px] md:h-[570px] h-[540px] max-w-sm mx-auto">
                    <a
                      href={m.seeMedia}
                      target="_blank"
                      rel="noreferrer"
                      className="flex justify-center lg:h-[200px] h-[150px] bg-gray-200"
                    >
                      <img
                        class="object-cover md:h-16 h-10 my-auto"
                        src={m.img}
                        alt=""
                      />
                    </a>
                    <div class="p-5">
                      <a href={m.seeMedia} target="_blank" rel="noreferrer">
                        <h5 class="mb-2 lg:text-2xl text-xl font-bold tracking-tight text-gray-900">
                          {m.topic}
                        </h5>
                      </a>
                      <p class="mb-3 font-normal text-gray-700 lg:text-base text-sm">
                        {m.detail}
                      </p>
                      <div className="flex justify-end">
                        <a
                          href={m.seeMedia}
                          target="_blank"
                          rel="noreferrer"
                          class=" absolute inline-flex items-center md:px-3 md:py-2 px-2 py-1 text-sm font-medium text-center text-white hover:text-[#54B435] bg-[#54B435] transition-colors duration-300 border-2 border-[#54B435] md:rounded-lg rounded hover:bg-white bottom-5"
                        >
                          {t("m_readmore")}
                          <i class="fa-solid fa-arrow-right pl-3"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <Trips />

      <div>
        <div className="2xl:px-40 xl:px-24 lg:px-20 px-5">
          <div className="flex justify-center my-10">
            <p className="font-bold text-center xl:text-4xl lg:text-3xl md:text-2xl text-xl">
              {t("whychoose")}
            </p>
          </div>
          <div className="mt-20 text-xl">
            <div className="text-center">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {choose.map((c) => (
                  <div>
                    <img
                      src={c.img}
                      className="w-16 mx-auto mb-4 xl:w-20"
                      alt=""
                    />
                    <p className="mb-10 text-sm xl:mx-10 lg:mx-10 mx-2 lg:text-base">
                      {c.details}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="bg-gray-100 leanding-normal tracking-wide">
          <div class="container mx-auto w-full overflow-hidden relative">
            <div class="w-full h-full absolute z-10">
              <div
                class="w-1/4 h-full absolute z-50 left-0"
                style={{
                  background:
                    "linear-gradient(to right, #edf2f7 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              ></div>
              <div
                class="w-1/4 h-full absolute z-50 right-0"
                style={{
                  background:
                    "linear-gradient(to left, #edf2f7 0%, rgba(255, 255, 255, 0) 100%)",
                }}
              ></div>
            </div>

            <div
              class="carousel-items flex items-center justify-center"
              style={{
                width: "fit-content",
                animation: "carouselAnim 60s infinite alternate linear",
              }}
            >
              {galleryImages.map((image, index) => (
                <div
                  class="carousel-focus flex items-center flex-col relative bg-white  my-10  rounded-lg shadow-lg"
                  style={{ width: "350px" }}
                >
                  <img
                    class="w-full md:h-[250px] h-[150px] object-cover shadow-2xl"
                    alt="Img"
                    src={image}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <WhatsApp />
      <ScrollToTopButton />
    </div>
  );
}
